import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import element from 'element-ui'
// import element from './element/index'
import 'element-ui/lib/theme-chalk/index.css'
// import anhui from './store/anhui'
import './config/rem'
import 'lib-flexible'
import axios from 'axios'
// 配置请求的跟路径
// axios.defaults.baseURL = 'http://211.149.176.35:9001'
axios.defaults.baseURL =
  process.env.NODE_ENV == 'development'
    ? 'https://api.cdksense.com/api/'
    : 'https://api.cdksense.com/api/'
// axios.defaults.timeout = 10000
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.interceptors.request.use((config) => {
  // console.log(config)
  // window.sessionStorage.setItem('token','9f8e55ea-fd5b-4ca6-a698-7bc45f240f88')

  config.headers.Token = window.sessionStorage.getItem('token')
  // 在最后必须 return config
  return config
})
// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    if (response.status === 200) {
      return response.data
      // 这个返回值应根据接口的返回值进行调整，不一定必须是.data
    } else {
      console.error('请求错误')
      console.error(response)
    }
    return response
  },
  function (error) {
    // 拦截操作 token过期401  没有权限403
    switch (error.response.status) {
      case 401:
        element.Message.closeAll()
        element.Message.error(error.response.data.msg)
        window.sessionStorage.removeItem('token')
        setTimeout(() => {
          router.push('/login')
        }, 1000)
        break
      case 403:
        element.Message.closeAll()
        element.Message.error(error.response.data.msg)
        break
      default:
        break
    }
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

Vue.prototype.$http = axios

Vue.use(element)
// Vue.use(anhui)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
